import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Content, { HTMLContent } from "../components/Content";

import "./actualites-index.scss";

export const ActualitesIndexTemplate = ({
  image,
  title,
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content;
  return (
    <div className="ActualitesIndex">
      <div className="Layout__header">
        {image && (
          <PreviewCompatibleImage
            className="ActualitesIndex__picture"
            imageInfo={{
              image: image,
              alt: `Header banner ${title}`,
            }}
          />
        )}
        <h1>{title}</h1>
      </div>
      <div className="Layout__body">
        <PostContent
          className="Markdown ActualitesIndex__description"
          content={content}
        />
        <BlogRoll />
      </div>
    </div>
  );
};

const ActualitesIndexPage = ({ data = {} }) => {
  const { markdownRemark } = data || {};
  const { frontmatter, html } = markdownRemark || {};
  const { image, title } = frontmatter || {};
  return (
    <Layout>
      <ActualitesIndexTemplate
        content={html}
        contentComponent={HTMLContent}
        image={image}
        title={title}
      />
    </Layout>
  );
};

ActualitesIndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ActualitesIndexPage;

export const ActualitesIndexQuery = graphql`
  query ActualitesIndexPage {
    markdownRemark(frontmatter: { templateKey: { eq: "actualites-index" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
